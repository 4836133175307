import { eventEmitter } from '../../common/baseData'

class Facebook {
  constructor (debugMode = false) {
    // Enable/Disable debug
    this._debugMode = debugMode
  }

  /**
   * Подключаем скрипт и инициализируем eS
   */
  async load () {
    let self = this
    !function (f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      t.onload = () => {
        if (self._debugMode) {
          console.log('facebookLoaded')
          console.trace()
        }
        eventEmitter.emit('facebookLoaded')
      }
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    }(window, document, 'script',
      'https://connect.facebook.net/en_US/fbevents.js')
    fbq('init', '541309812696667')
    fbq('track', 'PageView')
  }

  purchase(order_data) {
    let order_price = Number(order_data['ORDER']['PRICE'])
    let product_ids = []

    //console.log(order_data)

    if (!order_data.hasOwnProperty('GRID') || !order_data.GRID.hasOwnProperty('ROWS')) return

    // подготавливаем данные по товарам
    for (let basket_id in order_data.GRID.ROWS) {
      if (!order_data.GRID.ROWS.hasOwnProperty(basket_id) || !order_data.GRID.ROWS[basket_id].hasOwnProperty('data')) continue;
      let product_data = order_data.GRID.ROWS[basket_id].data
      if (product_data.hasOwnProperty('PRODUCT_ID'))
        product_ids.push(product_data.PRODUCT_ID)
    }

    // facebook trigger event
    if (order_price > 0 && product_ids.length > 0) {
      let purchase_data = {
        value: order_price,
        currency: 'UAH',
        content_ids: product_ids,
        content_type: 'product'
      }
      //console.log(purchase_data)
      fbq('track', 'Purchase', purchase_data)
    }
  }
}

export default Facebook