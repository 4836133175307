import {
  isAuth,
  sessid,
  userId,
  userEsputnikId,
  userName,
  userEmail,
  lang,
  userPhone,
  currentCityName,
  eventEmitter
} from 'devSrc/common/baseData'
import Cookies from 'js-cookie'

export class eSTracker {
  constructor (debug_mode = false) {
    window.sessionStorage.setItem('EsputnikLoaded', 'no')
    // Enable/Disable debug
    this.debugMode = debug_mode
    // Bind events
    this.bindEvents()
  }

  pageVarians = {
    home: {
      A: 'r1665v2103',
      B: 'r1665v2103'
    },
    notFound: {
      A: 'r1369v1762',
      B: 'r1369v1762'
    },
    product: {
      A: 'r1664v2098',
      B: 'r1664v2098'
    },
    category: {
      A: 'r1705v2146',
      B: 'r1705v2147'
    }
  }

  /**
   * Подключаем скрипт и инициализируем eS
   */
  async loadScript () {
    let s = document.createElement('script')
    s.async = true
    s.src = 'https://statics.esputnik.com/scripts/69A02D61F92045D0954F757725B58B1E.js'
    s.onload = () => {
      eventEmitter.emit('EsputnikLoaded')
      window.sessionStorage.setItem('EsputnikLoaded', 'yes')
    }
    let r = document.scripts[0]
    r.parentNode.insertBefore(s, r)
    var f = function () {
      f.c(arguments)
    }
    f.q = []
    f.c = function () {
      f.q.push(arguments)
    }
    window['eS'] = window['eS'] || f

    eS('init', {
      TRACKING: false,
      RECOMS: false,
      APP_INBOX: true
    }, {
      getAuthTokenCallback: () => {
        return new Promise(resolve => {
          let formData = new FormData()

          formData.append('sessid', sessid)
          formData.append('action', 'getToken')

          fetch('/local/esputnik.php', {
            method: 'POST',
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            body: formData
          })
            .then(response => response.json())
            .then(response => resolve(response.token))
            .catch((reason) => reject(reason))
        })
      }, language: lang === 'ua' ? 'uk' : 'ru',
    })
  }

  bindEvents () {
    // Отправка аналитики "StatusCart" при изменении состояния корзины
    eventEmitter.on('eSTrackerBasketRefresh', (data) => {
      this.StatusCart(data.needCheck)
    })
  }

  /**
   * Данные посетителя сайта
   * Необходимо для матчинга контактов с Cookies
   * Передается во всех события аналитики
   * @returns {{GeneralInfo: {}, user_email: *, user_phone: *, user_es_contact_id: *}}
   * @constructor
   */
  get GeneralInfo () {
    return (isAuth)
      ? {
        'user_email': userEmail,
        'user_phone': userPhone,
        'user_es_contact_id': userEsputnikId
      }
      : false
  }

  /**
   *
   * @returns {string}
   */
  getUserGroup() {
    if (Cookies.get('abTestEsputnik') === undefined) {
      let group_id = Math.floor(Math.random() * (2 - 1 + 1)) + 1
      let user_group = group_id === 1 ? 'A' : 'B'
      Cookies.set('abTestEsputnik', user_group, { expires: 365 })
    }
    return Cookies.get('abTestEsputnik')
  }

  /**
   *
   * @param page
   * @returns {string}
   */
  determinePageVariant (page) {
    let user_group = this.getUserGroup()
    return this.pageVarians.hasOwnProperty(page) && this.pageVarians[page].hasOwnProperty(user_group) ? this.pageVarians[page][user_group] : ''
  }

  /**
   *
   * @param page
   * @param reccomsData
   * @returns {Promise<unknown>}
   */
  loadReccoms (page, reccomsData = {}) {
    reccomsData.variantId = this.determinePageVariant(page)

    return new Promise((resolve, reject) => {

      // Debug Mode
      if (this.debugMode) {
        console.info('Debug sendEvent - loadReccoms')
        console.info('getRecommendations', reccomsData)
      }

      eS('getRecommendations',
        reccomsData,
        (error, products) => {
          if (products.length > 0) {
            if (error) {
              reject(error)
            } else {
              if (Array.isArray(products) && products.length) resolve(products)
              reject('Empty response from esputnik')
            }
          }
        }
      )
    })
  }

  sendGeneralInfo () {
    let generalInfo = this.GeneralInfo
    if (generalInfo instanceof Object) {
      try {
        eS('sendEvent', 'GeneralInfo', generalInfo)
      } catch (e) {
        console.error('Error eSputnik send event "GeneralInfo"')
      }
    }
  }

  /**
   * Возвращает GUID состояния корзины пользователя
   * @returns {false|string}
   */
  getBasketStateGUID () {
    return Cookies.get('BITRIX_SM_BASKET_STATE_GUID')
  }

  /**
   * Получаем данные из localstorage по состоянию корзины
   * @return {string|null}
   */
  getBasketStateGUIDFromStorage () {
    return localStorage.getItem('basketStateGUID')
  }

  /**
   * Устанавливаем данные в localstorage по состоянию корзины
   * @param basketStateGUID {string}
   */
  setBasketStateGUIDToStorage (basketStateGUID) {
    localStorage.setItem('basketStateGUID', basketStateGUID)
  }

  /**
   * Событие аналитики "MainPage"
   * Передает, что была посещена главная страница сайта
   * @constructor
   */
  MainPage () {
    try {
      eS('sendEvent', 'MainPage')

      // Debug Mode
      if (this.debugMode) {
        console.dir('Debug sendEvent - MainPage')
        console.dir('MainPage')
      }
    } catch (e) {
      console.error('Error eSputnik send event "MainPage"')
    }

  }

  /**
   * Событие аналитики "NotFound"
   * Передает, что была посещена отсутствующая страница сайта (страница 404)
   * @constructor
   */
  NotFound () {
    try {
      eS('sendEvent', 'NotFound')

      // Debug Mode
      if (this.debugMode) {
        console.dir('Debug sendEvent - NotFound')
        console.dir('NotFound')
      }
    } catch (e) {
      console.error('Error eSputnik send event "NotFound"')
    }

  }

  /**
   * Событие аналитики "CustomerData"
   * Передает контактные данные авторизованного пользователя
   * @constructor
   */
  CustomerData () {
    try {
      eS('sendEvent', 'CustomerData', {
        'CustomerData': {
          'user_email': userEmail,
          'user_name': userName,
          'user_client_id': userId,
          'user_card_id': userEsputnikId,
          'user_phone': userPhone,
          'user_city': currentCityName
        }
      })

      // Debug Mode
      if (this.debugMode) {
        console.dir('Debug sendEvent - CustomerData')
        console.dir({
          'CustomerData': {
            'user_email': userEmail,
            'user_name': userName,
            'user_client_id': userId,
            'user_card_id': userEsputnikId,
            'user_phone': userPhone,
            'user_city': currentCityName
          }
        })
      }
    } catch (e) {
      console.error('Error eSputnik send event "CustomerData"')
    }
  }

  /**
   * Событие аналитики "ProductPage"
   * Передает данные по товару со страницы карточки товара
   * @param {object} productData
   * @constructor
   */
  ProductPage (productData) {
    try {
      let id = productData.id
      let price = (!(productData.price instanceof String)) ? productData.price.toString() : productData.price
      let inStock = (productData.isInStock) ? 1 : 0

      eS('sendEvent', 'ProductPage', {
        'ProductPage': {
          'productKey': id,
          'price': price,
          'isInStock': inStock
        }
      })

      // Debug Mode
      if (this.debugMode) {
        console.dir('Debug sendEvent - ProductPage')
        console.dir({
          'ProductPage': {
            'productKey': id,
            'price': price,
            'isInStock': inStock
          }
        })
      }
    } catch (e) {
      console.error('Error eSputnik send event "ProductPage"')
    }
  }

  /**
   * Событие аналитики "StatusCart"
   * Передает данные по товарам в корзине
   * Свойство "GUID" связано с состоянием корзины
   * @param needCheck Необходимо ли проверять состояние корзины по GUID
   * @constructor
   */
  StatusCart (needCheck) {
    try {
      let products = this.prepareProductsBasketData()
      let basketStateGUID = this.getBasketStateGUID()
      // Получаем значение из storage
      let oldBasketStateGUID = this.getBasketStateGUIDFromStorage()
      // Сравниваем с текушим
      let guidUpdated = !(basketStateGUID.localeCompare(oldBasketStateGUID) === 0)

      if (this.debugMode) {
        console.dir('products')
        console.dir(products)

        console.dir('oldBasketStateGUID')
        console.dir(oldBasketStateGUID)

        console.dir('basketStateGUID')
        console.dir(basketStateGUID)

        console.dir('needCheck')
        console.dir(needCheck)

        console.dir('guidUpdated')
        console.dir(guidUpdated)
      }

      // Устанавливаем текущее состояние только в случае изменения по набору товаров
      if (needCheck) this.setBasketStateGUIDToStorage(basketStateGUID)

      // Отменяем отправку
      if (needCheck === true && !guidUpdated)
        return

      eS('sendEvent', 'StatusCart', {
        'StatusCart': products,
        'GUID': basketStateGUID
      })

      // Debug Mode
      if (this.debugMode) {
        console.dir('Debug sendEvent - StatusCart')
        console.dir({
          'StatusCart': products,
          'GUID': basketStateGUID
        })
      }
    } catch (e) {
      console.log('Error eSputnik send event "StatusCart"')
      console.error(e.message)
    }
  }

  /**
   * Событие аналитики "PurchasedItems"
   * Передает данные по созданному заказу.
   * Свойство "GUID" связано с состоянием корзины для текущего заказа
   * @param {object} orderData
   * @constructor
   */
  PurchasedItems (orderData) {
    if (typeof orderData !== 'object') return false
    try {
      //Prepare data
      let orderId = orderData.ORDER_ID
      let products = []
      let basketStateGuid = orderData.BASKET_STATE_GUID

      Object.keys(orderData.GRID.ROWS).forEach(key => {
        if (!orderData.GRID.ROWS[key].hasOwnProperty('data')) return
        let product = orderData.GRID.ROWS[key].data

        if (this.debugMode) console.dir(product)

        products.push({
          'productKey': product.PRODUCT_ID,
          'price': product.PRICE.toString(),
          'quantity': product.QUANTITY.toString(),
          'currency': product.CURRENCY
        })
      })

      //Send event
      eS('sendEvent', 'PurchasedItems', {
        'OrderNumber': orderId,
        'PurchasedItems': products,
        'GUID': basketStateGuid
      })

      // Debug Mode
      if (this.debugMode) {
        console.dir('Debug sendEvent - PurchasedItems')
        console.dir({
          'OrderNumber': orderId,
          'PurchasedItems': products,
          'GUID': basketStateGuid
        })
      }
    } catch (e) {
      console.error('Error eSputnik send event "PurchasedItems"')
    }
  }

  /**
   * Событие аналитики "CategoryPage"
   * Передает идентификатор категории, на странице которой находится пользователь
   * @param {Number} categoryId
   * @returns {boolean|void}
   * @constructor
   */
  CategoryPage (categoryId) {
    if (categoryId == null) return false
    try {
      eS('sendEvent', 'CategoryPage', {
        'CategoryPage': {
          'categoryKey': categoryId
        }
      })

      // Debug Mode
      if (this.debugMode) {
        console.dir('Debug sendEvent - CategoryPage')
        console.dir({
          'CategoryPage': {
            'categoryKey': categoryId
          }
        })
      }
    } catch (e) {
      console.error('Error eSputnik send event "CategoryPage"')
    }
  }

  SearchRequest (query, has_items) {
    try {
      eS('sendEvent', 'SearchRequest', {
        'SearchRequest': {
          'search': query,
          'isFound': has_items
        }
      })

      // Debug Mode
      if (this.debugMode) {
        console.dir('Debug sendEvent - SearchRequest')
        console.dir({
          'SearchRequest': {
            'search': query,
            'isFound': has_items
          }
        })
      }
    } catch (e) {
      console.error('Error eSputnik send event "SearchRequest"')
    }
  }

  /**
   * Add to wish list event
   *
   * eS('sendEvent', 'AddToWishlist', {
   *         'AddToWishlist': {
   *             'productKey': '24-MB02',
   *             'price': '153',
   *             'isInStock': 1
   *         }
   * });
   *
   * @param productKey
   * @param price
   * @param isInStock
   * @constructor
   */
  AddToWishlist (productKey, price, isInStock) {
    try {
      eS('sendEvent', 'AddToWishlist', {
        'AddToWishlist': {
          'productKey': productKey,
          'price': price,
          'isInStock': isInStock
        }
      })

      // Debug Mode
      if (this.debugMode) {
        console.dir('Debug sendEvent - AddToWishlist')
        console.dir({
          'AddToWishlist': {
            'productKey': productKey,
            'price': price,
            'isInStock': isInStock
          }
        })
      }
    } catch (e) {
      console.error('Error eSputnik send event "AddToWishlist"')
    }
  }

  /**
   * Формирует данные по товарам для события "StatusCart"
   *
   * Пример
   * item = {
   *   'productKey': '11423',
   *   'price': '153',
   *   'quantity': '1',
   *   'currency': 'UAH'
   * }
   * @returns {*[]}
   */
  prepareProductsBasketData () {
    let productsData = []

    try {
      let basketItemsData = JSON.parse(window.localStorage.getItem('basketItemsData'))

      if (this.debugMode) {
        console.info('prepareProductsBasketData basketItemsData')
        console.dir(basketItemsData)
      }

      if (basketItemsData.length > 0) {
        basketItemsData.forEach(basketItem => {
          productsData.push({
            productKey: basketItem.productId,
            price: basketItem.price,
            quantity: basketItem.quantity
          })
        })
      }
    } catch (reason) {
      console.log(reason)
    }

    return productsData
  }

  ProductImpressions (data) {
    try {
      eS('sendEvent', 'ProductImpressions', {
        'ProductImpression': data
      })

      // Debug Mode
      if (this.debugMode) {
        console.dir('Debug sendEvent - ProductImpressions')
        console.dir({
          'ProductImpressions': {
            'ProductImpression': data
          }
        })
      }
    } catch (e) {
      console.error('Error eSputnik send event "ProductImpressions"')
    }
  }
}