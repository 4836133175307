import {eventEmitter, lang} from "devSrc/common/baseData";
import langData2 from './lang.js'

export class EmailWidget {
    loadScript() {
      let getMessageText = langData2[lang];

        let script = document.createElement('div');
        script.id = 'pp-email-widget-73hgm78';
        script.className = 'ru_l';
        script.innerHTML = `<link rel="stylesheet" href="/email-widgets/widget/form-v3.css" media="all">
    <div class="email-widget-bubble"></div>
    <div class="pp-popup email-widget-popup">
        <div class="popup-wrap" id="pp-form">
            <a class="close-popup" href="#"></a>
            <div class="formcont-83yhb1">
                <div class="popup-title">
                    <span>${getMessageText["title1"]}</span>
                    ${getMessageText["title2"]}
                </div>
                <form method="post" class="pp-form" id="widgetForm-26e3r23" name="widgetForm-26e3r23" novalidate="">
                    <div class="rowinput">
                        <input class="popup-txt" type="text" id="pp-name" name="pp-name" value="" placeholder="${getMessageText["namePlaceholder"]}">
                        <div class="error_msg_name"></div>
                    </div>
                    <div class="rowinput">
                        <input class="popup-txt" type="email" id="pp-email" name="pp-email" value="" placeholder="Email">
                        <div class="error_msg_email"></div>
                    </div>
                    
                    <div class="btnrow">
                        <button class="popup-btn" type="submit" name="submit">${getMessageText["subscribe"]}</button>
                    </div>
                    <div class="subs-error-26e3r23"></div>


                    <div class="promo_details">${getMessageText["discountDetail"]}
                        <div class="promo_details_txt">${getMessageText["discountDescr1"]}<div class="line_break"></div> ${getMessageText["discountDescr2"]}</div>
                    </div>
                    <div class="policy-text">
                        ${getMessageText["policyText1"]}<div class="line_break"></div> ${getMessageText["policyText2"]}</div>
                </form>
            </div>

        </div>
    </div>
`;

        let script2 = document.createElement('script');
        script2.type ='text/javascript';
        script2.async = true;
        script2.src = lang === "ru" ? '/email-widgets/widget/script_ru.js' : '/email-widgets/widget/script.js';

        document.body.append(script, script2);

            }
}

export default EmailWidget