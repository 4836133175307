import {eventEmitter, lang, sessid, compareItems} from '../common/baseData';
import {Popup} from '../popup';

export class ProductCompare {
    constructor(params) {
        this.urlRequest = '/local/components/webhome/catalog.compare.result/ajax.php';

        this.comparedItems = Array.isArray(compareItems) ? compareItems : [];
        this.nodeList = {};
        this.dataList = {};

        let self = this;

        if (this.comparedItems.length > 0) {
            this.comparedItems.forEach(id => {
                self.dataList[id] = true;
            });
        }

        eventEmitter.on('handleCompareItems', params => {
            if (params.hasOwnProperty('node') && params.hasOwnProperty('range')) {
                console.log('handleCompareItems', params.node, params.range);
                self.handleDOMItems(params.node, params.range);
            }
        });
    }

    handleDOMItems(node, range) {
        try {
            let items = [], dataItems = {}, self = this;

            if (node instanceof HTMLElement)
                items.push(node);
            else if (node instanceof NodeList)
                items = Array.prototype.slice.call(node);

            if (items.length <= 0) return;

            items.forEach(item => {
                if (!self.isCorrectItem(item)) return;

                if (self.dataList.hasOwnProperty(item.dataset.id)
                    && self.dataList[item.dataset.id])
                    item.parentNode.classList.add('active');

                if (!self.dataList.hasOwnProperty(item.dataset.id))
                    self.dataList[item.dataset.id] = false;

                item.addEventListener('click', e => {
                    e.preventDefault();
                    self.onClickItem(e.target);
                });

                if (dataItems.hasOwnProperty(item.dataset.id) && dataItems[item.dataset.id] instanceof Array) {
                    dataItems[item.dataset.id].push(item);
                } else {
                    dataItems[item.dataset.id] = [];
                    dataItems[item.dataset.id].push(item);
                }
            });

            this.nodeList[range] = dataItems;

        } catch (error) {
            console.error(error);
        }
    }

    isCorrectItem(node) {
        return ((node.dataset.hasOwnProperty('id') && Number(node.dataset.id) > 0)
            && (node.dataset.hasOwnProperty('container') && node.dataset.container === 'compare'));
    }

    onClickItem(node) {
        if (this.dataList.hasOwnProperty(node.dataset.id)) {
            //If the product is already in your favorites - go to the list page
            if (this.dataList[node.dataset.id]) {
                if (node.dataset.hasOwnProperty('link'))
                    location.href = node.dataset.link;
                //Add to compare
            } else {
                this.add(Number(node.dataset.id))
                    .then(data => {
                        let result = data.result;

                        if (result.hasOwnProperty('status') && result.status) {
                            node.parentNode.classList.add('active');
                            this.dataList[node.dataset.id] = true;

                            if (result.hasOwnProperty('display_data') && result.display_data)
                                this.showAddResult(result.display_data);

                        } else if (result.hasOwnProperty('error')) {
                            console.dir(result.error);
                        }

                        if (result.hasOwnProperty('items')) {
                            this.comparedItems = result.items;
                        }
                        eventEmitter.emit('refreshCompareList', this.comparedItems);
                        eventEmitter.emit('updateCompareListState', this.nodeList.product[Number(node.dataset.id)]);
                    });
            }
        }
    }

    add(productId) {
        let self = this;
        return this.sendRequest({action: 'add', productId: productId})
            .then(response => {
                return response.json();
            });
    }

    remove(productId) {

    }

    showAddResult(data) {
        let popup = new Popup('compare');
        popup.body = this.getModalTemplate(data);
        popup.open();
    }

    getModalTemplate(data) {
        let result = ``;
        let productBlock = null;

        if(data.hasOwnProperty('product')
            && typeof data.product === 'object'
            && Object.keys(data.product).length > 0) {
                productBlock = `<div class="product-item">
			                                            <div class="img">
				                                            <a href="${data.product['url']}"><img src="${data.product['picture']}" alt="product"></a>
			                                            </div>
			                                            <div class="product-description">
				                                            <div class="code"><span>${data.product['vendor_code']}</span></div>
				                                            <div class="product-name">
					                                            <a href="${data.product['url']}">${data.product['name']}</a>
				                                            </div>
				                                            <div class="btn-buy_row">
					                                            <div class="price-block">
						                                            <div class="price-box">
						                                                ${data.product['price'].hasOwnProperty('PRINT_OLD_PRICE') 
                                                                            ? `<div class="price-old"><span>${data.product['price']['PRINT_OLD_PRICE']}</span></div>` : ``}
							                                            <div class="price"><span>${data.product['price']['PRINT_PRICE']}</span></div>
						                                            </div>
						                                        <div class="price-usd">${data.product['price']['PRINT_PRICE_USD']}</div>
					                                        </div>
				                                        </div>
			                                        </div>
		                                        </div>`;
        }

        let categoryListBlock = `<div class="compare-category">`;

        if(data.hasOwnProperty('categoryList')
            && Array.isArray(data.categoryList)
            && data.categoryList.length > 0) {
            data.categoryList.forEach(category => {
                categoryListBlock += `<div class="compare-category_item">
                                        <div class="compare-category_item-left">
                                            <div class="compare-category-img">
                                                <img src="${category['picture']}" alt="${category['name']}">
                                            </div>
                                            <div class="compare-category-info">
                                                <a href="${category['url']}" class="compare-category-name">${category['name']}</a>
                                                <span class="compare-category-num">${category['count']}</span>
                                            </div>
                                        </div>
                                        <span class="compare-category-link" onclick="window.location.href='${category['compare_url']}'">
                                            ${category['compare_link_name']}
                                        </span>
                                    </div>`;
            });
        }
        categoryListBlock += `</div>`;

        result = `<span class="modal-title">${data.title}</span>${productBlock + categoryListBlock}`;

        return result;
    }


    processResultFromSession(data) {
        if (!data.hasOwnProperty('items')) return;
        const items = data.items;

        for (let i in items) {
            if (!items.hasOwnProperty(i)
                || this.dataList.hasOwnProperty(items[i])) continue;

            this.dataList[items[i]] = true;

            for (let range in this.nodeList) {
                if (!this.nodeList.hasOwnProperty(range)
                    || !this.nodeList[range].hasOwnProperty(items[i])) continue;

                if (this.nodeList[range][items[i]] instanceof HTMLElement)
                    this.nodeList[range][items[i]].parentNode.classList.add('active');
            }
        }
    }

    sendRequest(data) {
        let formData = new FormData();
        formData.append('sessid', sessid);
        formData.append('lang', lang);

        if (Object.keys(data).length > 0) {
            for (let i in data) {
                if (data.hasOwnProperty(i))
                    formData.append(i, data[i]);
            }
        }

        return fetch(this.urlRequest, {body: formData, method: 'POST'});
    }

    compareFixedHead() {
        let self = this;
        var colW = $('.compare-table__col').outerWidth(),
            countCol = 0,
            scrollToTable = $('.product-description').offset().top - 70,
            rowFixedH = $('.compare-table__row-fixed').outerHeight();

        $(".compare-table__row-fixed  .compare-table__col").each(function () {
            if ($(this).is(':visible')) {
                countCol++;
            }
        });

        self.scrollToLastRow = $('[data-container="bottom"]').offset().top;

        eventEmitter.on('compareChangeCategory', () => {
            self.scrollToLastRow = $('[data-container="bottom"]').offset().top;
        });

        $(window).scroll(function () {
            if ($(this).scrollTop() > scrollToTable) {
                $('.compare-table__row-fixed').addClass('is-fixed');
                $('.compare-info').css('padding-top', rowFixedH + 'px');
                $('.compare-table__row-fixed').css({
                    'transform': 'translateX(' + - $('.compare-info').scrollLeft() + 'px' + ')',
                    'width': countCol * colW + 'px'
                });
                if ($(this).scrollTop() > self.scrollToLastRow) {
                    $('.compare-table__row-fixed').addClass('hide')
                } else {
                    $('.compare-table__row-fixed').removeClass('hide')
                }
            } else {
                $('.compare-table__row-fixed').removeClass('is-fixed');
                $('.compare-info').css('padding-top', '0');
                $('.compare-table__row-fixed').css({
                    'transform': 'translateX(0)',
                    'width': '100%'
                })
            }
        });

        $('.compare-info').scroll(function () {
            if ($('.compare-table__row-fixed').hasClass('is-fixed')) {
                $('.compare-table__row-fixed').css({
                    'transform': 'translateX(' + - $('.compare-info').scrollLeft() + 'px' + ')',
                    'width': countCol * colW + 'px'
                });
            };
        });
    }
}