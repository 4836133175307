let langData2 = {
	"ru": {
        "title1": "Хочешь скидку?",
        "title2": "Лови 150 ₴ за подписку\n",
        "namePlaceholder": "Имя",
        "female": "Женщина",
        "male": "Мужчина",
        "genderStatus": "ж",
        "genderStatus2": "м",
        "subscribe": "Подписаться",
        "discountDetail": "Детали акции\n",
        "discountDescr1": "*Промокод действителен при оформлении",
        "discountDescr2": "заказа на сумму от 1000 ₴.",
        "policyText1": "Не волнуйся - твоя почта в безопасности.",
        "policyText2": "Будем писать тебе только о наших акциях и новостях.",
	},
	"ua": {
		"title1": "Хочеш знижку?",
		"title2": "Лови 150 ₴ за підписку\n",
		"namePlaceholder": "Ім'я",
		"female": "Жінка",
		"male": "Чоловік",
        "genderStatus": "ж",
        "genderStatus2": "м",
		"subscribe": "Підписатися",
		"discountDetail": "Деталі акції",
		"discountDescr1": "*Промокод дійсний при оформленні",
		"discountDescr2": "замовлення на суму від 1000 ₴.",
		"policyText1": "Не хвилюйся - твоя пошта в безпеці.",
		"policyText2": "Писатимемо тобі тільки про наші акції та новини.",
	}
}

export default langData2