import {eventEmitter} from "devSrc/common/baseData";

export class SocialWidget {
    loadScript() {
        let script = document.createElement('div');
        script.className = 'social-contact-us-btn-holder';
        script.innerHTML = `<div class="follow-us-social-buttons-holder">
                                    <a rel="nofollow noopener" href="https://m.me/285832378222120" target="_blank" aria-label="Facebook" class="follow-us-social-buttons-holder-link" style="background: #3962a1; border-radius: 50%">
                                        <svg width="25" height="30" fill="#fff">
                                            <use xlink:href="#facebook-footer" />
                                        </svg>
                                    </a>
                                    <a rel="nofollow noopener" target="_blank" href="https://t.me/tiuachat_bot" aria-label="Telegram" class="follow-us-social-buttons-holder-link" style="background: #1e96c8; border-radius: 50%">
                                        <svg width="25" height="25" fill="#fff">
                                            <use xlink:href="#telegram-logo" />
                                        </svg>
                                    </a>
                                    <a rel="nofollow noopener" target="_blank" href="viber://pa/?chatURI=tehnoezh" aria-label="Viber" class="follow-us-social-buttons-holder-link" style="background: #7360f2; border-radius: 50%">
                                        <svg width="25" height="25" fill="#fff">
                                            <use xlink:href="#viber-logo" />
                                        </svg>
                                    </a>
                                    <a rel="nofollow noopener" target="_blank" href="https://www.instagram.com/tehnoyizhak/" aria-label="Instagram" class="follow-us-social-buttons-holder-link">
                                        <svg width="50" height="50">
                                            <use xlink:href="#follow-us-social-buttons-holder-link__img"/>
                                        </svg>
                                    </a>
                                    <a rel="nofollow noopener" href="tel:0800204402" aria-label="Call" class="follow-us-social-buttons-holder-link">
                                        <svg width="50" height="50">
                                            <use xlink:href="#call" />
                                        </svg>
                                    </a>
                                </div> 
                                <div class="main-button-holder">
                                    <svg width="50" height="25">
                                        <use xlink:href="#main-button-link" />
                                    </svg>
                                </div>
`;

                document.body.append(script);

                let contactUsHolder = document.querySelector('.social-contact-us-btn-holder');
                let socialNetworksHolder = document.querySelector('.follow-us-social-buttons-holder');


                contactUsHolder.addEventListener('click', () => {
                    socialNetworksHolder.classList.toggle('active');
                })

            }
}

export default SocialWidget