export class DynamicRemarketing {
    constructor() {
        window.dataLayer = window.dataLayer || [];
    }

    pushToDataLayer(data) {
        if(!data.hasOwnProperty('price') || !data.hasOwnProperty('items')
            || Number(data.price) <= 0 || data.items.length <= 0) return;

        let addItemList = [];
        if(Array.isArray(data.items)) {
            data.items.forEach(item => addItemList.push({
                'id': item,
                'google_business_vertical': 'retail'
            }));
        } else {
            addItemList.push({
                'id': data.items,
                'google_business_vertical': 'retail'
            });
        }

        window.dataLayer.push({
                'event': "gtagDynRem",
                'dynRemEventName': data.eventName,
                'dynRemValue': data.price,
                'dynRemFBItems': data.items,
                'dynRemItems': addItemList,
            });
    }

    pushFbqEvent(action, data) {
        if(!window.fbq) return;

        if(Object.keys(data).length > 0)
            fbq('track', action, data);
    }

}