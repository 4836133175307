class EsputnikSubscription {
  constructor (lang = 'ua', debugMode = false) {
    // Enable/Disable debug
    this._debugMode = false
    this._lang = lang
  }

  getLangSrc (lang) {
    let src = {
      'ua': 'https://secure.esputnik.com/8qG07eueKus',
      'ru': 'https://secure.esputnik.com/8q25VFoqSus'
    }
    return (src.hasOwnProperty(lang)) ? src[lang] : '#'
  }

  /**
   * Инициализируем iframe
   */
  init () {
    let node = document.getElementById('footer');
    if (!(node instanceof HTMLElement)) return;
    let newWidgetTab = document.createElement('div');
    newWidgetTab.innerHTML = `<iframe class="subs_iframe" title="Subscription" src="${this.getLangSrc(this._lang)}" frameborder="0"></iframe><style>.subs_iframe {display: block; height: 162px; width: 100%; overflow: hidden; border-top: 1px solid #ececec; } @media(max-width: 588px) {.subs_iframe {height: 230px; } }</style>`;
    node.parentNode.insertBefore(newWidgetTab, node);
  }
}

export default EsputnikSubscription