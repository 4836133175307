import { lang, sessid } from '../../common/baseData'
import axios from 'axios'

class Analytics {
  url = '/local/analytics.ajax.php'
  config = { headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' } }

  selectItem (item_id, list_name = '', list_id = '') {
    let body = JSON.stringify({
      event: 'selectItem',
      event_data: {
        item_id: item_id,
        list_name: list_name,
        list_id: list_id,
      },
      lang: lang,
      sessid: sessid
    })

    return axios.post(this.url, body, this.config)
  }

  viewItem (item_id) {
    return axios.post(this.url, JSON.stringify({
      sessid: sessid,
      event: 'viewItem',
      event_data: {
        item_id: item_id
      }
    }), this.config)
  }

  viewItemList (item_ids, list_name = '', list_id = '') {
    return new Promise(resolve => {
      let body = JSON.stringify({
        event: 'viewItemList',
        event_data: {
          item_ids: item_ids,
          list_name: list_name,
          list_id: list_id,
        },
        lang: lang,
        sessid: sessid
      })

      axios.post(this.url, body, this.config)
        .then(response => {
          resolve(response.data)
        })
        .catch(reason => {
          console.log(reason)
          resolve()
        })
    })
  }

  addToCart (item_id) {
    let body = JSON.stringify({
      event: 'addToCart',
      event_data: {
        item_id: item_id
      },
      lang: lang,
      sessid: sessid
    })

    axios.post(this.url, body, this.config)
      .then(response => {
        //console.dir(response)
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce: response.data
        })
      })
  }

  /**
   *
   * @param orderId
   * @returns {Promise<Object>}
   */
  purchase (orderId) {
    return new Promise((resolve) => {
      axios.post(this.url, JSON.stringify({
        event: 'orderPurchase',
        event_data: { order_id: orderId },
        lang: lang,
        sessid: sessid
      }), this.config)
        .then(response => {
          console.info(response)
          if (response.data !== null && !response.data.hasOwnProperty('error')) {
            window.dataLayer.push({ ecommerce: null })
            window.dataLayer.push({
              event: 'purchase',
              ecommerce: response.data
            })
          }
          resolve()
        })
        .catch(reason => {
          console.log(reason)
          resolve()
        })
    })
  }
}

export default Analytics