import {eventEmitter} from "devSrc/common/baseData";

export class SvgSprite {
    loadScript() {
        let script = document.createElement('div');
        script.className = 'svg-sprite';
        script.innerHTML = `
           <svg xmlns="http://www.w3.org/2000/svg" id="svg" version="1.1">
              <defs>
                  <symbol xmlns="http://www.w3.org/2000/svg" id="call" height="50" width="50" data-name="Call" viewBox="0 0 45 45">
                      <path d="M22.5,45A22.5,22.5,0,1,1,45,22.5,22.48,22.48,0,0,1,22.5,45Z" fill="#15b76c"/>
                      <path d="M33.39,28.55a7.6,7.6,0,0,0-2.44-2c-.27-.16-.87-.5-1.68-1h0A2.45,2.45,0,0,0,27,25.52l-1,.54A1.16,1.16,0,0,1,24.58,26a26.89,26.89,0,0,1-2.68-2.43,16.28,16.28,0,0,1-2-2.5h0a1.29,1.29,0,0,1,.05-1.4c.88-1.13,1.17-2.09.88-2.75A27.24,27.24,0,0,0,19,13.46c-.55-1-.85-1.55-1.56-1.55-1.48-.05-3.8,1.44-3.83,3.48s.88,5.23,3.87,9a28.84,28.84,0,0,0,4.94,4.88,16.21,16.21,0,0,0,3.48,2.21,8,8,0,0,0,3.58,1A3.66,3.66,0,0,0,33.39,28.55Z" fill="#fff"/>
                  </symbol>
                 
                  <symbol version="1.1" viewBox="0 0 60 60" width="50" height="25" style="" id="main-button-link" class="main-button-link">
                      <g fill="#fff"><path d="M34.225,10.494H14.775C6.628,10.494,0,17.122,0,25.27v11.345c0,7.733,6.313,14.315,14,14.845v6.481   c0,0.893,0.602,1.566,1.4,1.566c0.379,0,0.751-0.163,1.047-0.459l1.521-1.521c3.89-3.89,9.151-6.032,14.813-6.032h1.443   C42.372,51.494,49,44.819,49,36.615V25.27C49,17.122,42.372,10.494,34.225,10.494z"></path><path d="M45.225,0.494H25.775c-5.654,0-10.719,3.244-13.184,8.159c0.717-0.094,1.442-0.159,2.184-0.159h19.449   C43.475,8.494,51,16.019,51,25.27v11.345c0,1.296-0.16,2.553-0.436,3.765C56.101,38.122,60,32.65,60,26.615V15.27   C60,7.122,53.372,0.494,45.225,0.494z"></path></g>
                   </symbol>
                  
                   <symbol class="_2BUtRgNh" id="follow-us-social-buttons-holder-link__img" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="50" height="50">
                       <circle cx="24" cy="24" fill="#D60468" r="24"></circle><path d="M31.2 12.3H16.8c-2.5 0-4.5 2-4.5 4.5V31.2c0 2.5 2 4.5 4.5 4.5h14.4c2.5 0 4.5-2 4.5-4.5V16.8c0-2.5-2-4.5-4.5-4.5zm1.3 2.7h.5v4h-4v-4h3.5zm-11.8 6.6c.7-1 2-1.7 3.3-1.7s2.6.7 3.3 1.7c.5.7.8 1.5.8 2.4 0 2.3-1.9 4.1-4.1 4.1s-4.1-1.8-4.1-4.1c0-.9.3-1.7.8-2.4zm12.7 9.6c0 1.2-1 2.2-2.2 2.2H16.8c-1.2 0-2.2-1-2.2-2.2v-9.6h3.5c-.3.7-.5 1.6-.5 2.4 0 3.5 2.9 6.4 6.4 6.4 3.5 0 6.4-2.9 6.4-6.4 0-.8-.2-1.7-.5-2.4h3.5v9.6z" fill="#FFF"></path>
                   </symbol>
                  
                   <symbol id="phone-ico" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17 17" fill="none">
                       <path d="M4.90967 11.7671C7.30859 14.166 10.2305 16.0171 12.6128 16.0171C13.6836 16.0171 14.6216 15.6436 15.377 14.8135C15.8169 14.3237 16.0908 13.751 16.0908 13.1865C16.0908 12.7715 15.9331 12.373 15.5347 12.0908L12.9946 10.2812C12.6045 10.0156 12.2808 9.88281 11.9819 9.88281C11.6001 9.88281 11.2681 10.0986 10.8862 10.4722L10.2969 11.0532C10.2056 11.1445 10.0894 11.186 9.98145 11.186C9.84863 11.186 9.73242 11.1362 9.64111 11.0947C9.13477 10.8208 8.25488 10.0654 7.43311 9.25195C6.61963 8.43848 5.86426 7.55859 5.59863 7.04395C5.54883 6.95264 5.50732 6.83643 5.50732 6.71191C5.50732 6.604 5.54053 6.49609 5.63184 6.40479L6.21289 5.79883C6.58643 5.41699 6.80225 5.08496 6.80225 4.70312C6.80225 4.4043 6.66943 4.08057 6.39551 3.69043L4.61084 1.17529C4.32031 0.776855 3.91357 0.602539 3.46533 0.602539C2.91748 0.602539 2.34473 0.851562 1.86328 1.32471C1.05811 2.09668 0.701172 3.05127 0.701172 4.10547C0.701172 6.48779 2.51904 9.37646 4.90967 11.7671Z" fill="#0080CC"/>
                   </symbol>
                   
                <symbol xmlns="http://www.w3.org/2000/svg" id="right-arrow-blue-icon" width="4" height="12" viewBox="0 0 4 12" fill="none">
                    <path d="M1.11719 11.6494L3.05176 6.7959C3.14062 6.56348 3.25 6.27637 3.25 6.05078C3.25 5.83203 3.14062 5.53809 3.05176 5.3125L1.11719 0.458984C1.03516 0.253906 0.857422 0.137695 0.679688 0.137695C0.378906 0.137695 0.15332 0.356445 0.15332 0.650391C0.15332 0.800781 0.235352 0.999023 0.283203 1.13574L2.24512 6.05078L0.283203 10.9727C0.235352 11.1094 0.15332 11.3008 0.15332 11.458C0.15332 11.752 0.378906 11.9707 0.679688 11.9707C0.857422 11.9707 1.03516 11.8545 1.11719 11.6494Z" fill="#0080CC"/>
                </symbol>
                
                <symbol xmlns="http://www.w3.org/2000/svg" id="right-arrow-icon" width="4" height="12" viewBox="0 0 4 12" fill="none">
                    <path d="M1.11719 11.6494L3.05176 6.7959C3.14062 6.56348 3.25 6.27637 3.25 6.05078C3.25 5.83203 3.14062 5.53809 3.05176 5.3125L1.11719 0.458984C1.03516 0.253906 0.857422 0.137695 0.679688 0.137695C0.378906 0.137695 0.15332 0.356445 0.15332 0.650391C0.15332 0.800781 0.235352 0.999023 0.283203 1.13574L2.24512 6.05078L0.283203 10.9727C0.235352 11.1094 0.15332 11.3008 0.15332 11.458C0.15332 11.752 0.378906 11.9707 0.679688 11.9707C0.857422 11.9707 1.03516 11.8545 1.11719 11.6494Z" fill="#C4C4C4"/>
                </symbol>
                
                <symbol xmlns="http://www.w3.org/2000/svg" version="1.1" id="cancel-ico" viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve" fill="#006fcc">
                    <path style="fill:#E6E7E8;" opacity="0.45" d="M240,8C111.87,8,8,111.87,8,240s103.87,232,232,232s232-103.87,232-232S368.13,8,240,8z M336,336  l-96-96l-96,96l96-96l-96-96l96,96l96-96l-96,96L336,336z"/>
                    <g>
                        <path style="fill:#006fcc;" d="M240,480C107.452,480,0,372.548,0,240S107.452,0,240,0s240,107.452,240,240   C479.85,372.486,372.486,479.85,240,480z M240,16C116.288,16,16,116.288,16,240s100.288,224,224,224s224-100.288,224-224   C463.859,116.347,363.653,16.141,240,16z"/>
                        <path style="fill:#006fcc;" d="M251.312,240l90.344-90.344c3.069-3.178,2.982-8.243-0.196-11.312c-3.1-2.994-8.015-2.994-11.116,0   L240,228.688l-90.344-90.344c-3.178-3.069-8.243-2.981-11.312,0.197c-2.994,3.1-2.994,8.015,0,11.115L228.688,240l-90.344,90.344   c-3.178,3.069-3.266,8.134-0.196,11.312c3.069,3.178,8.134,3.266,11.312,0.196c0.067-0.064,0.132-0.13,0.196-0.196L240,251.312   l90.344,90.344c3.178,3.07,8.242,2.982,11.312-0.196c2.995-3.1,2.995-8.016,0-11.116L251.312,240z"/>
                    </g>
                </symbol>
                
                <symbol id="facebook-footer" xmlns="http://www.w3.org/2000/svg"  viewBox="88.428 12.828 107.543 207.085">
                    <path d="M158.232 219.912v-94.461h31.707l4.747-36.813h-36.454V65.134c0-10.658 2.96-17.922 18.245-17.922l19.494-.009V14.278c-3.373-.447-14.944-1.449-28.406-1.449-28.106 0-47.348 17.155-47.348 48.661v27.149H88.428v36.813h31.788v94.461l38.016-.001z"/>
                </symbol>
                  
                <symbol xmlns="http://www.w3.org/2000/svg" version="1.1" id="instagram-footer" x="0px" y="0px" viewBox="0 0 169.063 169.063" style="enable-background:new 0 0 169.063 169.063;" xml:space="preserve">
                    <g><path d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752   c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407   c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752   c17.455,0,31.656,14.201,31.656,31.655V122.407z"/>
                        <path d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561   C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561   c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"/>
                        <path d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78   c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78   C135.661,29.421,132.821,28.251,129.921,28.251z"/></g>
                </symbol>
                
                <symbol xmlns="http://www.w3.org/2000/svg" id="tik-tok-footer" data-name="tik-tok-footer" viewBox="0 0 139.06 159.35">
                  <path d="M120.94,34.46c-1.07-.55-2.1-1.16-3.11-1.81-2.93-1.93-5.61-4.21-7.99-6.79-5.96-6.82-8.18-13.73-9-18.57h.03c-.68-4.02-.4-6.62-.36-6.62h-27.14V105.6c0,1.41,0,2.8-.06,4.18,0,.17-.02,.33-.03,.51,0,.08,0,.15-.02,.23v.06c-.58,7.64-4.93,14.5-11.59,18.29-3.42,1.95-7.29,2.97-11.23,2.96-12.64,0-22.89-10.31-22.89-23.04s10.25-23.04,22.89-23.04c2.39,0,4.77,.37,7.05,1.12l.03-27.63c-13.97-1.8-28.05,2.32-38.84,11.36-4.68,4.06-8.61,8.91-11.62,14.33-1.15,1.97-5.47,9.91-5.99,22.79-.33,7.31,1.87,14.88,2.91,18.01v.07c.66,1.84,3.21,8.13,7.37,13.44,3.35,4.25,7.31,7.99,11.75,11.09v-.07l.07,.07c13.14,8.93,27.7,8.34,27.7,8.34,2.52-.1,10.97,0,20.56-4.55,10.64-5.04,16.69-12.55,16.69-12.55,3.87-4.49,6.95-9.6,9.1-15.12,2.46-6.45,3.28-14.2,3.28-17.29V53.5c.33,.2,4.71,3.1,4.71,3.1,0,0,6.32,4.05,16.17,6.69,7.07,1.88,16.6,2.27,16.6,2.27v-26.94c-3.34,.36-10.11-.69-17.05-4.15Z"/>
                </symbol>
                
                <symbol xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 163 163" id="telegram-footer">
                  <path d="M81.5,0C36.49,0,0,36.49,0,81.5s36.49,81.5,81.5,81.5,81.5-36.49,81.5-81.5S126.51,0,81.5,0Zm37.73,55.37c-1.22,12.89-6.51,44.18-9.23,58.56-1.15,6.11-3.39,8.14-5.56,8.35-4.75,.41-8.35-3.12-12.89-6.11-7.19-4.68-11.2-7.6-18.19-12.21-8.08-5.29-2.85-8.21,1.76-12.96,1.22-1.22,22.05-20.22,22.46-21.92,.07-.2,.07-1.02-.41-1.43-.48-.41-1.15-.27-1.7-.14-.75,.14-12.15,7.74-34.34,22.73-3.26,2.24-6.18,3.33-8.82,3.26-2.92-.07-8.48-1.63-12.69-2.99-5.09-1.63-9.16-2.51-8.82-5.36,.2-1.49,2.24-2.99,6.04-4.55,23.75-10.31,39.56-17.17,47.5-20.49,22.6-9.43,27.35-11.06,30.4-11.13,.68,0,2.17,.14,3.19,.95,.81,.68,1.02,1.56,1.15,2.24,.14,.68,.27,2.1,.14,3.19Z"/>
                </symbol>
                
                <symbol xmlns="http://www.w3.org/2000/svg" id="you-tube-footer" data-name="you-tube-footer" viewBox="0 0 197.75 138.4">
                   <path d="M192.44,22.19c-2.25-8.4-8.86-15.02-17.27-17.27C159.94,.85,98.88,.85,98.88,.85c0,0-61.07,0-76.3,4.08C14.17,7.17,7.56,13.79,5.31,22.19,1.23,37.42,1.23,69.2,1.23,69.2c0,0,0,31.78,4.08,47.01,2.25,8.4,8.86,15.02,17.27,17.27,15.23,4.08,76.3,4.08,76.3,4.08,0,0,61.07,0,76.3-4.08,8.4-2.25,15.02-8.86,17.27-17.27,4.08-15.23,4.08-47.01,4.08-47.01,0,0,0-31.78-4.08-47.01ZM79.35,98.49V39.91l50.74,29.29-50.74,29.29Z"/>
                </symbol>
                
                <symbol xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 80.85 67" id="telegram-about">
                  <g>
                    <path id="Path-3" d="M5.52,28.9C27.22,19.47,41.67,13.21,48.93,10.17,69.57,1.55,73.92,.06,76.71,0c.62,0,1.98,.12,2.91,.87,.74,.62,.93,1.43,1.05,2.05s.25,1.92,.12,2.91c-1.12,11.78-5.95,40.37-8.43,53.51-1.05,5.58-3.1,7.44-5.08,7.63-4.34,.37-7.63-2.85-11.78-5.58-6.57-4.28-10.23-6.95-16.62-11.16-7.38-4.84-2.6-7.5,1.61-11.84,1.12-1.12,20.15-18.48,20.53-20.03,.06-.19,.06-.93-.37-1.3-.43-.37-1.05-.25-1.55-.12-.68,.12-11.1,7.07-31.38,20.77-2.98,2.05-5.64,3.04-8.06,2.98-2.67-.06-7.75-1.49-11.6-2.73-4.65-1.49-8.37-2.29-8.06-4.9,.19-1.36,2.05-2.73,5.52-4.15Z"/>
                  </g>
                </symbol>
                
                <symbol xmlns="http://www.w3.org/2000/svg" id="youtube-about" data-name="youtube-about" viewBox="0 0 40 40">
                  <defs>
                    <style>
                      .cls-1 {
                        fill: #fff;
                      }
                
                      .cls-2 {
                        fill: #d83232;
                      }
                    </style>
                  </defs>
                  <circle class="cls-2" cx="20" cy="20" r="19.47"/>
                  <path class="cls-1" d="M28.18,15.89c-.2-.73-.78-1.31-1.51-1.51-1.33-.36-6.67-.36-6.67-.36,0,0-5.34,0-6.67,.36-.73,.2-1.31,.78-1.51,1.51-.36,1.33-.36,4.11-.36,4.11,0,0,0,2.78,.36,4.11,.2,.73,.78,1.31,1.51,1.51,1.33,.36,6.67,.36,6.67,.36,0,0,5.34,0,6.67-.36,.73-.2,1.31-.78,1.51-1.51,.36-1.33,.36-4.11,.36-4.11,0,0,0-2.78-.36-4.11Zm-9.89,6.67v-5.12l4.44,2.56-4.44,2.56Z"/>
                </symbol>
                
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" id="blog-arrow-right">
                    <path d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z"/>
                </symbol>
                
                <symbol xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 248 204" id="twitter-logo">
                    <path d="M221.95 51.29c.15 2.17.15 4.34.15 6.53 0 66.73-50.8 143.69-143.69 143.69v-.04c-27.44.04-54.31-7.82-77.41-22.64 3.99.48 8 .72 12.02.73 22.74.02 44.83-7.61 62.72-21.66-21.61-.41-40.56-14.5-47.18-35.07 7.57 1.46 15.37 1.16 22.8-.87-23.56-4.76-40.51-25.46-40.51-49.5v-.64c7.02 3.91 14.88 6.08 22.92 6.32C11.58 63.31 4.74 33.79 18.14 10.71c25.64 31.55 63.47 50.73 104.08 52.76-4.07-17.54 1.49-35.92 14.61-48.25 20.34-19.12 52.33-18.14 71.45 2.19 11.31-2.23 22.15-6.38 32.07-12.26-3.77 11.69-11.66 21.62-22.2 27.93 10.01-1.18 19.79-3.86 29-7.95-6.78 10.16-15.32 19.01-25.2 26.16z"/>
                </symbol>
                
                <symbol xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" id="viber-logo">
                    <g><path d="m23.155 13.893c.716-6.027-.344-9.832-2.256-11.553l.001-.001c-3.086-2.939-13.508-3.374-17.2.132-1.658 1.715-2.242 4.232-2.306 7.348-.064 3.117-.14 8.956 5.301 10.54h.005l-.005 2.419s-.037.98.589 1.177c.716.232 1.04-.223 3.267-2.883 3.724.323 6.584-.417 6.909-.525.752-.252 5.007-.815 5.695-6.654zm-12.237 5.477s-2.357 2.939-3.09 3.702c-.24.248-.503.225-.499-.267 0-.323.018-4.016.018-4.016-4.613-1.322-4.341-6.294-4.291-8.895.05-2.602.526-4.733 1.93-6.168 3.239-3.037 12.376-2.358 14.704-.17 2.846 2.523 1.833 9.651 1.839 9.894-.585 4.874-4.033 5.183-4.667 5.394-.271.09-2.786.737-5.944.526z"/><path d="m12.222 4.297c-.385 0-.385.6 0 .605 2.987.023 5.447 2.105 5.474 5.924 0 .403.59.398.585-.005h-.001c-.032-4.115-2.718-6.501-6.058-6.524z"/><path d="M16.151 10.193c-.009.398.58.417.585.014.049-2.269-1.35-4.138-3.979-4.335-.385-.028-.425.577-.041.605 2.28.173 3.481 1.729 3.435 3.716zM15.521 12.774c-.494-.286-.997-.108-1.205.173l-.435.563c-.221.286-.634.248-.634.248-3.014-.797-3.82-3.951-3.82-3.951s-.037-.427.239-.656l.544-.45c.272-.216.444-.736.167-1.247-.74-1.337-1.237-1.798-1.49-2.152-.266-.333-.666-.408-1.082-.183h-.009c-.865.506-1.812 1.453-1.509 2.428.517 1.028 1.467 4.305 4.495 6.781 1.423 1.171 3.675 2.371 4.631 2.648l.009.014c.942.314 1.858-.67 2.347-1.561v-.007c.217-.431.145-.839-.172-1.106-.562-.548-1.41-1.153-2.076-1.542z"/><path d="m13.169 8.104c.961.056 1.427.558 1.477 1.589.018.403.603.375.585-.028-.064-1.346-.766-2.096-2.03-2.166-.385-.023-.421.582-.032.605z"/></g>
                </symbol>
                
                <symbol id="telegram-logo" xmlns="http://www.w3.org/2000/svg" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve">
                <g id="_x33_35-telegram"><g><path d="M484.689,98.231l-69.417,327.37c-5.237,23.105-18.895,28.854-38.304,17.972L271.2,365.631     l-51.034,49.086c-5.646,5.647-10.371,10.372-21.256,10.372l7.598-107.722L402.539,140.23c8.523-7.598-1.848-11.809-13.247-4.21     L146.95,288.614L42.619,255.96c-22.694-7.086-23.104-22.695,4.723-33.579L455.423,65.166     C474.316,58.081,490.85,69.375,484.689,98.231z" /></g></g>
</symbol>

                <symbol xmlns="http://www.w3.org/2000/svg" id="user-ico" width="12" height="13" viewBox="0 0 12 13" fill="none">
                    <svg >
                        <path d="M3 3.33325C3 4.98725 4.346 6.33325 6 6.33325C7.654 6.33325 9 4.98725 9 3.33325C9 1.67925 7.654 0.333252 6 0.333252C4.346 0.333252 3 1.67925 3 3.33325ZM11.3333 12.9999H12V12.3333C12 9.76059 9.906 7.66659 7.33333 7.66659H4.66667C2.09333 7.66659 0 9.76059 0 12.3333V12.9999H11.3333Z" fill="#777777"/>
                    </svg>
                </symbol>
               
              </defs>
           </svg>
       `;

        document.body.append(script);



    }
}

export default SvgSprite


