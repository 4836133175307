import {apiBaseUrl, axiosInstance} from '../common/baseData';

class EsputnikApi {
  async findByEmail(email) {
    let response = await axiosInstance.post(`${apiBaseUrl}esp/findByEmail`, {email: email});
    return response.data ? response.data : null;
  }

  async getByExtId(extId) {
    let response = await axiosInstance.post(`${apiBaseUrl}esp/getByExtId`, {extId: extId});
    return response.data ? response.data : null;
  }

  async subscribe(email, id) {
    let response = await axiosInstance.post(`${apiBaseUrl}esp/subscribe`, {
      id: id,
      email: email,
    });
    return response.data ? response.data : null;
  }

  async createContactByEmail(email) {
    let response = await axiosInstance.post(`${apiBaseUrl}esp/createContactByEmail`, {
      email: email,
    });
    return response.data ? response.data : null;
  }
}

export default EsputnikApi;